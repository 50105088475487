<template>
  <table class="print-table">
    <thead>
      <tr>
        <th width="24">序号</th>
        <th>其他要求</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in selfData" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.qi_ta_yao_qiu }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selfData: []
    }
  },
  mounted() {
    this.selfData = this.$util.deepCopy(this.data)
  }
}
</script>

<style lang="less" scoped>
.print-table {
  margin-top: 5px;
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  .text-right {
    text-align: right;
  }
}
.print-table caption {
  padding-bottom: 5px;
  text-align: left;
  font-weight: bold;
}
.print-table th,
.print-table td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2px;
  text-align: left;
  overflow-wrap: anywhere;
}
.print-table th {
  font-weight: normal;
  background: #e0e5eb;
}
</style>
